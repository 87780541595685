<template>
  <div class="success">
    <div class="main">
      <img class="left" src="@/assets/imgs/success.png">
      <div class="right">
        <img class="logo" src="@/assets/imgs/logo.png">
        <div class="title">Thank You For Your<br/>Recent Purchase,<br/>Let Your Journey Begin!</div>
        <div class="web">Product will be shipped to you soon!<br/>Kindly check your email address for more information.</div>
        <div class="btn" @click="router.push('/')">Start</div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'

  const router = useRouter()
</script>

<style lang="less" scoped>
.success{
  height: 100vh;
  background: url("~@/assets/imgs/bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .main{
    width: 1200px;
    position: relative;

    .left{
      width: 603px;
    }
    .right{
      position: absolute;
      top: 50%;
      right: 72px;
      transform: translateY(-50%);
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .logo{
        width: 86px;
        margin-bottom: 40px;
      }
      .title{
        font-size: 44px;
        line-height: 52px;
        margin-bottom: 16px;
      }
      .web{
        font-size: 22px;
        color: #697377;
        margin-bottom: 60px;
        line-height: 32px;
      }
      .btn{
        width: 272px;
        height: 42px;
        border-radius: 10px;
        background: #06C8A1;
        font-size: 14px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: "DM Sans Bold";
      }
    }
  }
}
</style>
